@import '../../../../../themes/tv_electronic_electron_1_7_v1/_dev/css/partials/variables';
@import '~slick-carousel/slick/slick';

#fa_products {
    .fa_products_wrapper {
        position: relative;
        display: flex;

        .fa_products_control {
            position: relative;
            display: flex;
            align-items: baseline;
            margin-left: auto;
            width: 6rem;
            
            .slick-next,
            .slick-prev {
                position: relative;
                transform: none;
                top: 20%;

                &:before {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $blue;
                    width: 36px;
                    height: 36px;
                    background-color: $white;
                    border-radius: 50%;
                }
            }

            .slick-next {
                right: 0;
            }
        }
    }

    .fa_productsslides {
        .slick-slide {
            margin-right: $base-font-size;
        }
    }
}